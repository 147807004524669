import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../context/authContext'
import axiosInstance from '../utils/apiClient'

const AboutUser = () => {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)
  const { logout, auth } = useContext(AuthContext)
  const [titlePage, setTitlePage] = useState('')
  const portfolio_id = auth?.user?.portfolio_id

  // Toggle dropdown open state
  const toggleDropdown = () => setIsOpen(!isOpen)

  // Close the dropdown if clicked outside of it
  const closeDropdown = (event) => {
    if (!event.target.closest('.dropdown-container')) {
      setIsOpen(false)
    }
  }


  useEffect(() => {
    if (portfolio_id) {
      axiosInstance.get('/portfolio/' + portfolio_id)
      .then((res) => {
        setTitlePage(res.data?.result?.portfolio_name)
      }).catch((error) => {
        console.error(error)
      })

    }
  }, [portfolio_id])


  useEffect(() => {
    window.addEventListener('click', closeDropdown)

    // Cleanup event listener
    return () => window.removeEventListener('click', closeDropdown)
  }, [])

  const handleSignOut = () => {
    logout()
    navigate('/sign-in')
  }

  return (
    <div className="w-full mb-10 p-8 flex justify-between bg-white border-2 rounded rounded-2xl border-gray-200">
      <h1 className="text-2xl font-bold">{titlePage}</h1>
      <div className="relative inline-block text-left dropdown-container">
        <div>
          <span
            className="text-3xl text-slate-500 material-symbols-outlined cursor-pointer"
            onClick={toggleDropdown}
          >
            account_circle
          </span>
        </div>
        {isOpen && (
          <div
            className="origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
            role="menu"
            aria-orientation="vertical"
          >
            <div className="flex flex-col gap-12">
              <div className="p-4 nav-item cursor-pointer" onClick={handleSignOut}>
                <span className="material-symbols-rounded">logout</span>
                Sign Out
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default AboutUser
import React, { useState } from 'react'
import TenantDetails from './TenantDetails'
import { formatDollarWithSymbol, formatPercent } from '../utils/formats'
import { getMoveOutProbabilityColor } from '../utils/config';
import apiClient from '../utils/apiClient';

const ExistingCustomersTable = ({ rows, handleChange }) => {
  const [selectedRow, setSelectedRow] = useState(-1)
  const [updatedTenants, setUpdateTenants] = useState([])
  const [isOpenDetail, setIsOpenDetail] = useState(false)

  const openTenantDetail = async (tenant) => {
    setIsOpenDetail(true)
    setSelectedRow(tenant.id)
  }

  const handleNewRateChange = (id, tenant, value) => {
    const tenants = [...updatedTenants]
    if (value) {
      const tenantIndex = tenants.findIndex((item) => item.tenant_id === tenant.tenant_id);

      if (tenantIndex > -1) {
        tenants[tenantIndex] = tenant;
      } else {
        tenants.push(tenant);
      }

      console.log(tenants);

      setUpdateTenants(tenants)
      handleChange(id)
    }
  }

  const handleExcludeChange = (id, tenant, value) => {
    const tenants = [...updatedTenants]
   
    if (value) {
      const tenantIndex = tenants.findIndex((item) => item.tenant_id === tenant.tenant_id);

      if (tenantIndex > -1) {
        tenants[tenantIndex] = tenant;
      } else {
        tenants.push(tenant);
      }

      setUpdateTenants(tenants)
      handleChange(id)
    }

    setUpdateTenants(tenants)
    handleChange(id)
  }

  const handleClose = (id) => {
    setIsOpenDetail(false)
    setSelectedRow(-1)
  }

  const handleSaveChange = async (id) => {
    setIsOpenDetail(false)
    setSelectedRow(-1)

    try {
      const data = updatedTenants.map((tenant) => { 
        return {
          id: tenant.ecri_id,
          new_rate: parseFloat(tenant.new_rate),
          exclude_submit: tenant.exclude_submit
        }
      });

      apiClient.post(`/ecri/bulk-update`, data)
      .then(function (response) {
        setIsOpenDetail(false)
        setSelectedRow(-1);
        setUpdateTenants([]);
      }).catch(function (error) {
        console.error(error)
        setIsOpenDetail(false)
        setSelectedRow(-1)
      })
    } catch (error) {
      console.error('Error update data: ', error)
    }
  }

  const checkEdited = () => {
    return updatedTenants.length > 0;
  }

  return (

      <tbody>
        {rows &&
          rows.map((item, index) => (
            <>
              <tr key={index} className={`${selectedRow === item.id && 'bg-red-100'}`}>
                <td>
                  <div className="bg-neutral-100 p-2 rounded rounded-lg text-wrap w-4/5">
                    {item.facility_name}
                    <br />
                    {item.address}
                  </div>
                </td>
                <td>
                  {item.city}, {item.state}
                </td>
                <td>{item.tenants.length}</td>
                <td className="bg-neutral-100">{formatPercent(item.avr_rate_increase_percent)}</td>
                <td className="bg-neutral-100">{formatDollarWithSymbol(item.avr_rate_increase_amount)}</td>
                <td className="bg-neutral-100">{formatDollarWithSymbol(item.largest_rate_increase)}</td>
                <td className="bg-neutral-100">
                  {item.avr_moveout_probability ? (
                    <span className="px-6 py-2 bg-green-200 rounded rounded-md" style={{
                      backgroundColor: getMoveOutProbabilityColor(item.avr_moveout_probability * 100)
                    }}>
                      {(item.avr_moveout_probability*100.0).toFixed(1)}%
                    </span>
                  ) : (
                    ''
                  )}
                </td>
                <td className="text-center">
                  {selectedRow === item.id ? (
                    <button
                      className="2xl:px-12 py-1 rounded rounded-full border border-primary bg-primary text-white relative"
                      onClick={()=> checkEdited(item) ? handleSaveChange() : handleClose(item.id)}
                    >
                      <span>{checkEdited() ? 'Save Changes' : 'Cancel'}</span>
                      <span className="material-symbols-outlined absolute right-1 top-1/2 transform -translate-y-1/2">
                        cancel
                      </span>
                    </button>
                  ) : (
                    <button
                      className="2xl:px-12 py-1 border border-black rounded rounded-full"
                      disabled={!item.tenants.length || isOpenDetail}
                      onClick={() => openTenantDetail(item)}
                    >
                      {checkEdited(item)
                        ? 'View Edits'
                        : 'Review Increases'}
                    </button>
                  )}
                </td>
              </tr>
              {selectedRow === item.id && (
                <tr key={`${index}-expanded`}>
                  <td colSpan={10} className="!p-0 overflow-hidden">
                    <TenantDetails
                      rows={item.tenants}
                      handleChange={(tenant, value) => handleNewRateChange(item.id, tenant, value)}
                      handleExcludeChange={(tenant, value) => handleExcludeChange(item.id, tenant, value)}
                    />
                  </td>
                </tr>
              )}
            </>
          ))}
      </tbody>
  )
}

export default ExistingCustomersTable

import React, { useEffect, useRef, useState } from "react"
import { formatDollar, formatDollarWithSymbol, formatPercent } from "../utils/formats"
import CurrencyInput from 'react-currency-input-field'

const SORT_FIELDS = {
  unit_type: 'unit_type',
  unit_style: 'unit_style',
  physical_occupancy: 'physical_occupancy',
  vacant_unit: 'available_units',
  today_street_rate: 'std_rate',
  recommended_street_rate: 'new_std_rate',
  difference_amount: 'difference_amount',
  difference_percent: 'difference_percent',
}

const UnitTypeRow = ({ data, handleChange }) => {
  const [unit, setUnit] = useState(data)

  const handleNewRateChange = (newUnit, value) => {
    const temp = {...newUnit, new_std_rate: value}
    setUnit(temp)
    handleChange(temp)
  }

  const handleLockUnitRate = (newUnit, value) => {
    const temp = {...newUnit, locked: value}
    setUnit(temp)
    handleChange(temp)
  }

  return (
    <tr>
      <td>{unit.unit_type}</td>
      <td style={{ maxWidth: 150, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }} title={unit.unit_style}>
        {unit.unit_style}
      </td>
      <td>{unit.physical_occupancy?.toFixed(2)}%</td>
      <td>{unit.available_units}</td>
      <td>${formatDollar(unit.std_rate)}</td>
      <td>${unit.locked ? '-' : formatDollar(unit.new_std_rate)}</td>
      <td>
        <span className="px-6 py-2 bg-green-200 rounded rounded-md">
          {unit.locked ? '$-' : formatDollarWithSymbol(unit.difference_amount)}
        </span>
      </td>
      <td>{unit.locked ? '-%' : formatPercent(unit.difference_percent)}</td>
      <td className="w-24">
        {
          unit.locked
          ? <div className="w-full px-4 py-2 border border-gray-700 rounded-md">$-</div>
          : <CurrencyInput
              prefix="$"
              disableGroupSeparators={true}
              defaultValue={unit.new_std_rate}
              decimalsLimit={2}
              onValueChange={(value) => handleNewRateChange(unit, value)}
            />
        }
      </td>
      <td align="center"><input type="checkbox" checked={unit.locked} onChange={(e) => handleLockUnitRate(unit, e.target.checked)}/></td>
    </tr>
  )
}

const DropdownFilter = ({units, label, filterUnits, sortUnits}) => {
  const uniqueUnitTypes = [...new Set(units.map(unit => unit.unit_style?.replace(/\s*,\s*/g, ',').split(',')).flat())];
  const [selectedStyles, setSelectedStyles] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [dropdownStyle, setDropdownStyle] = useState({});

  const toggleDropdown = () => setIsOpen(!isOpen)

  const closeDropdown = (event) => {
    if (!event.target.closest('.dropdown-container')) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    window.addEventListener('click', closeDropdown)
    return () => window.removeEventListener('click', closeDropdown)
  }, [])

  useEffect(() => {
    if (isOpen && dropdownRef.current) {
      const dropdown = dropdownRef.current;
      const th = dropdown.parentElement;
      const thRect = th.getBoundingClientRect();
      const dropdownRect = dropdown.getBoundingClientRect();

      const spaceBelow = window.innerHeight - thRect.bottom;
      const spaceAbove = thRect.top;

      if (spaceBelow < dropdownRect.height && spaceAbove > dropdownRect.height) {
        setDropdownStyle({ top: 'auto', bottom: '100%' });
      } else {
        setDropdownStyle({ top: '85%', bottom: 'auto' });
      }
    }
  }, [isOpen]);

  const handleStyleChange = (style) => {
    const newSelectedStyles = selectedStyles.includes(style)
      ? selectedStyles.filter(s => s !== style)
      : [...selectedStyles, style];

    setSelectedStyles(newSelectedStyles);
    filterUnits(newSelectedStyles);
  };

  return (
    <th className="dropdown-container relative cursor-pointer select-none" onClick={toggleDropdown} >
      <div className="inline-block text-left w-36">
        <div>
          <div>
            {label}
          </div>
        </div>
        {isOpen && (
          <div
            className="origin-top-right absolute left-0 mt-2 w-[20rem] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
            role="menu"
            aria-orientation="vertical"
            style={{ ...dropdownStyle }}
            ref={dropdownRef}
          >
            <div
              className="block px-4 py-2 text-xs text-gray-700 hover:bg-gray-100 flex items-center gap-2"
              onClick={() => sortUnits(SORT_FIELDS.unit_style, 'asc')}
            >
              <span className="material-symbols-rounded w-1/12">arrow_upward</span>
              <p className="font-normal text-sm normal-case">Sort Ascending</p>
            </div>
            <div
              className="block px-4 py-2 text-xs text-gray-700 hover:bg-gray-100 flex items-center gap-2 border-b border-slate-300"
              onClick={() => sortUnits(SORT_FIELDS.unit_style, 'desc')}
            >
              <span className="material-symbols-rounded w-1/12">arrow_downward</span>
              <p className="font-normal text-sm normal-case">Sort Descending</p>
            </div>
            {uniqueUnitTypes.map(unit_stype => (
              <div
                className="block px-4 py-2 text-xs text-gray-700 hover:bg-gray-100 flex items-center gap-2"
                onClick={() => handleStyleChange(unit_stype)}
                key={unit_stype}
              >
                <input
                  type="checkbox"
                  className="cursor-pointer w-1/12"
                  value={unit_stype}
                  checked={selectedStyles.includes(unit_stype)}
                  onChange={() => handleStyleChange(unit_stype)}
                />
                <p className="font-normal text-sm normal-case">{unit_stype}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </th>
  )
}

const UnitTypeStatistics = ({ rows, handleChange }) => {
  const [filteredUnits, setFilteredUnits] = useState(rows);
  const [currentFilteredUnits, setCurrentFilteredUnits] = useState(rows);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  const handleFilter = (unit_styles) => {
    let updatedFilteredUnits;

    if (unit_styles.length === 0) {
      updatedFilteredUnits = rows;
      setCurrentFilteredUnits(updatedFilteredUnits);
    } else {
      updatedFilteredUnits = rows.filter(unit =>
        unit_styles.some(style => unit.unit_style.includes(style))
      );
      setCurrentFilteredUnits(updatedFilteredUnits);
    }

    const sortedUnits = !!sortConfig.direction ? sortUnits(updatedFilteredUnits, sortConfig.key, sortConfig.direction) : updatedFilteredUnits;
    setFilteredUnits(sortedUnits);
  };

  const sortUnits = (units, key, direction) => {
    return [...units].sort((a, b) => {
      if (a[key] === null && b[key] === null) {
        return 0;
      }
      if (a[key] === null) {
        return 1;
      }
      if (b[key] === null) {
        return -1;
      }

      if (a[key] < b[key]) {
        return direction === 'asc' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  const handleSort = (key, cus_direction='') => {
    let direction = cus_direction || 'asc';
    if (!cus_direction && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    if (!cus_direction && sortConfig.key === key && sortConfig.direction === 'desc') {
      direction = null;
    }

    const sortedUnits = !!direction ? sortUnits(filteredUnits, key, direction) : currentFilteredUnits
    setFilteredUnits(sortedUnits);
    setSortConfig({ key, direction });
  }

  const renderSortIcon = (key) => {
    const className = sortConfig.key !== key ? '' :
                        sortConfig.direction === 'asc' ? '-up' :
                          sortConfig.direction === 'desc' ? '-down' : '';

    return (
      <img src={`/assets/images/sort${className}.svg`} alt={`Sort Icon${className}`} className="w-[1rem] p-1 inline" />
    )
  }

  return (
    <div className="flex items-center">
      <table className="w-full table-unit-type-statistics">
        <thead className="text-gray-600">
          <tr className="uppercase text-left">
            <th
              onClick={() => handleSort(SORT_FIELDS.unit_type)}
              className="cursor-pointer select-none"
            >
              Unit Type &nbsp;
              {renderSortIcon(SORT_FIELDS.unit_type)}
            </th>
            <DropdownFilter
              units={rows}
              label={(
                <div>
                  Attributes
                  &nbsp;
                  {renderSortIcon(SORT_FIELDS.unit_style)}
                </div>
              )}
              filterUnits={handleFilter}
              sortUnits={handleSort}
            />
            <th
              onClick={() => handleSort(SORT_FIELDS.physical_occupancy)}
              className="cursor-pointer select-none"
            >
              Physical
              <br />
              Occupancy
              &nbsp;
              {renderSortIcon(SORT_FIELDS.physical_occupancy)}
            </th>
            <th
              onClick={() => handleSort(SORT_FIELDS.vacant_unit)}
              className="cursor-pointer select-none"
            >
              Vacant
              <br />
              Units
              &nbsp;
              {renderSortIcon(SORT_FIELDS.vacant_unit)}
            </th>
            <th
              onClick={() => handleSort(SORT_FIELDS.today_street_rate)}
              className="cursor-pointer select-none"
            >
              Today's
              <br />
              Street
              <br />
              Rate
              &nbsp;
              {renderSortIcon(SORT_FIELDS.today_street_rate)}
            </th>
            <th
              onClick={() => handleSort(SORT_FIELDS.recommended_street_rate)}
              className="cursor-pointer select-none"
            >
              Recommended
              <br />
              Street Rate
              &nbsp;
              {renderSortIcon(SORT_FIELDS.recommended_street_rate)}
            </th>
            <th
              onClick={() => handleSort(SORT_FIELDS.difference_amount)}
              className="cursor-pointer select-none"
            >
              Difference $
              &nbsp;
              {renderSortIcon(SORT_FIELDS.difference_amount)}
            </th>
            <th
              onClick={() => handleSort(SORT_FIELDS.difference_percent)}
              className="cursor-pointer select-none"
            >
              Difference %
              &nbsp;
              {renderSortIcon(SORT_FIELDS.difference_percent)}
            </th>
            <th>New Rate</th>
            <th align="center">Lock Rate</th>
          </tr>
        </thead>
        <tbody>
          {filteredUnits && filteredUnits.map((unit) => (
            <UnitTypeRow
              key={unit.id}
              data={unit}
              handleChange={handleChange}
            /> 
          ))}
        </tbody>
      </table>
      <div className="flex justify-center min-w-48">
        <a href="/competitors" className="uppercase text-xs text-primary border-2 border-primary rounded rounded-md p-3">
          View Competitors
        </a>
      </div>
    </div>
  )
}

export default UnitTypeStatistics

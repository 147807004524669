import { useState, useEffect } from 'react'
import Label from './atom/Label'

const competitorTypes = ['Direct', 'Indirect', 'Non-competitor']
const colors = {
  Direct: 'green',
  Indirect: 'pink',
  'Non-competitor': 'gray',
}


const CompetitorTypeSelect = ({ selected, onChange }) => {
  const [isOpen, setIsOpen] = useState(false)

  // Toggle dropdown open state
  const toggleDropdown = () => setIsOpen(!isOpen)

  // Close the dropdown if clicked outside of it
  const closeDropdown = (event) => {
    if (!event.target.closest('.dropdown-container')) {
      setIsOpen(false)
    }
  }

  const handleSelected = (option) => {
    setIsOpen(false)
    onChange(option)
  }

  useEffect(() => {
    window.addEventListener('click', closeDropdown)
    return () => window.removeEventListener('click', closeDropdown)
  }, [])

  return (
    <div className="relative inline-block text-left w-36 dropdown-container">
      <div>
        <div onClick={toggleDropdown}>
          {selected ? (
            <Label color={colors[selected]}>{selected}</Label>
          ) : (
            <span className="text-red-500">Select...</span>
          )}
        </div>
      </div>
      {isOpen && (
        <div
          className="origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
          role="menu"
          aria-orientation="vertical"
        >
          <div className="py-1" role="none">
            {competitorTypes.map((option, index) => (
              <a
                key={index}
                href="#"
                className="block px-4 py-3 text-sm text-gray-700 hover:bg-gray-100"
                role="menuitem"
                onClick={() => handleSelected(option)}
              >
                {option}
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default CompetitorTypeSelect
const Pagination = ({ page, totalPages, handlePagination }) => {
  return (
    <div className="my-6 flex justify-center pagination">
      <div className="flex items-center">
        <button disabled={page < 2} onClick={() => handlePagination(page - 1)}>
          <span className="font-bold material-symbols-rounded">chevron_left</span>
        </button>
        {totalPages > 0 &&
          [...Array(totalPages).keys()].map((number) => (
            <button
              key={number}
              className={page === number + 1 ? 'active' : ''}
              onClick={() => handlePagination(number + 1)}
            >
              {number + 1}
            </button>
          ))}
        <button
          disabled={!totalPages || page === totalPages}
          onClick={() => handlePagination(page + 1)}
        >
          <span className="font-bold material-symbols-rounded">chevron_right</span>
        </button>
      </div>
    </div>
  )
}

export default Pagination
import React from 'react'

const Modal = ({ isOpen, children }) => {
  if (!isOpen) return null

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-8 rounded-lg shadow-lg relative">
        {children}
      </div>
    </div>
  )
}

export default Modal

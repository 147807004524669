// AuthContext.js
import React, { createContext, useEffect, useState } from 'react'

const AuthContext = createContext()

const AuthProvider = ({ children }) => {
  const token = localStorage.getItem('token')
  const [isAuthenticated, setIsAuthenticated] = useState(!!token)
  const [auth, setAuth] = useState(null)

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    setAuthCred(user)
  }, [])

  const setAuthCred = (user) => {
    if (user) {
      setAuth(user)
      localStorage.setItem('user', JSON.stringify(user))
    }
  }

  const login = async (token, user) => {
    localStorage.setItem('token', token)
    localStorage.setItem('user', JSON.stringify(user))
    setAuth(user)
    setIsAuthenticated(!!token)
  }

  const logout = async () => {
    localStorage.clear()
    setIsAuthenticated(null)
  }

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, login, logout, auth, setAuthCred }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }

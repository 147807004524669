import React from 'react'
import AboutUser from './AboutUser'
import Navbar from './Navbar'

const Dashboard = ({ children }) => {
  return (
    <div className="flex bg-gray-100">
      <Navbar />
      <main className="flex-1 px-8 mt-12">
        <AboutUser />
        {children}
      </main>
    </div>
  )
}

export default Dashboard

import React from 'react'

const SelectGroup = ({ options, onChange, selected }) => {

  const handleButtonClick = (option) => {
    if (onChange) {
      onChange(option)
    }
  }

  return (
    <div className="flex gap-4">
      {options.map((option) => (
        <button
          key={option}
          className={`select-group ${option === selected ? 'active' : ''}`}
          onClick={() => handleButtonClick(option)}
          type="button"
        >
          {option}
        </button>
      ))}
    </div>
  )
}

export default React.forwardRef((props, ref) => <SelectGroup innerRef={ref} {...props} />);


import React, { useRef, useEffect, useState } from 'react'
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api'
import { getDistance } from 'geolib'


const libraries = ['places']
const mapContainerStyle = {
  width: '100%',
  height: '600px',
}
const defaultZoom = 14

const MapComponent = ({ center, locations }) => {

  const [prevLocation, setPrevLocation] = useState()

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries,
  })

  // Create a ref for the GoogleMap component
  const mapRef = useRef()

  // Function to handle map centering
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map
  }, [])

  // Use useEffect to update the center when it changes
  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current
      const dis = getDistance(
        { latitude: prevLocation.lat, longitude: prevLocation.lng },
        { latitude: center.lat, longitude: center.lng }
      )
      if (dis > 5000) {
        map.setZoom(10)
        setTimeout(() => {
          map.panTo(center)
        }, 100)

        setTimeout(() => {
          map.setZoom(14)
        }, 200)
      } else {
          map.panTo(center)
      }
    }
    setPrevLocation(center)
  }, [center])

  if (loadError) return 'Error loading maps'
  if (!isLoaded) return 'Loading maps'

  const showMarkers = () => {
    return locations.map((marker, index) => (
      <Marker
        key={index}
        position={marker.position}
        title={marker.title}
        opacity={marker.position.lat === center.lat && marker.position.lng === center.lng ? 1 : 0.5}
      />
    ))
  }

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={defaultZoom}
      center={center}
      onLoad={onMapLoad}
    >
      {showMarkers()}
    </GoogleMap>
  )
}

export default MapComponent

import React, { useRef } from 'react'

const SearchInput = ({ placeholder, value, onChange }) => {
  const iconRef = useRef(null)

  const handleFocus = () => {
    iconRef.current?.classList.add('text-primary')
  }

  const handleBlur = () => {
    iconRef.current.classList.remove('text-primary')
  }

  return (
    <div className="relative w-full max-w-96">
      <input
        type="text"
        placeholder={placeholder}
        className="font-normal text-base w-full max-w-96 h-12 pl-4 pr-10 rounded rounded-lg"
        value={value}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={onChange}
      />
      <span
        ref={iconRef}
        className="material-symbols-rounded absolute right-3 top-4 transform"
      >
        search
      </span>
    </div>
  )
}

export default SearchInput

import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import PasswordStrengthBar from 'react-password-strength-bar';
import axios from 'axios'

const SignIn = () => {
  const myParam = useLocation().search;
  const emailParam = new URLSearchParams(myParam).get("email");
  const token = new URLSearchParams(myParam).get("token");

  const navigate = useNavigate()
  const [email, setEmail] = useState(emailParam);
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [resetPasswordError, setResetPasswordError] = useState(false);
  const [errMessage, setErrMessage] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = {
      token,
      email,
      password,
      confirmPassword
    }

    axios.post('/api/reset-password', form).then((result) => {
      navigate('/sign-in');
    }).catch((error) => {
      setErrMessage(error.response.data.error)
      setResetPasswordError(true);
    })
  }

  const onChangeScore = (score, feedback) => {
    setSubmitDisabled(!(score >= 2))
  }

  return (
    <div className="w-screen h-screen bg-slate-100 flex items-center justify-center">
      <div className="w-1/2 bg-white rounded-2xl flex flex-wrap">
        <form
          className="flex-1 p-24 bg-red-100 flex flex-col gap-6 rounded-l-2xl"
          onSubmit={handleSubmit}
        >
          <h1 className="text-red-500 text-2xl font-semibold">Welcome! Set Your Password</h1>
          <input
            type="email"
            placeholder="Email"
            className="rounded-full w-full border-none outline-none"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            className="rounded-full w-full border-none outline-none"
            onChange={(e) => setPassword(e.target.value)}
          />
          {password && (
            <div style={{ fontSize: 12, color: 'rgb(85, 85, 85)' }}>
              To conform with our Strong Password policy, you are required to use a sufficiently
              strong password.
            </div>
          )}
          {password && (
            <PasswordStrengthBar
              className="passwordStrengthBar"
              password={password}
              barColors={['#3d658f', '#ef4836', '#f6b44d', '#2b90ef', '#25c281']}
              onChangeScore={(score, feedback) => onChangeScore(score, feedback)}
            />
          )}
          <input
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            className="rounded-full w-full border-none outline-none"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {confirmPassword && confirmPassword !== password && (
            <div className="font-sm text-primary">Confirm password does not match</div>
          )}
          {(resetPasswordError) && <div className="font-sm text-primary">{errMessage}</div>}
          <button
            disabled={submitDisabled || !password || confirmPassword !== password}
            type="submit"
            className="w-full rounded-lg bg-red-500 text-white uppercase"
          >
            Submit
          </button>
        </form>
        <div className="flex-1 p-24 flex items-center justify-center">
          <img className="w-4/5 mx-auto" src="/assets/images/logo.svg" alt="App Logo" />
        </div>
      </div>
    </div>
  )
}

export default SignIn

import React, { useContext, useEffect } from 'react'
import SelectGroup from '../components/atom/SelectGroup'
import axios from 'axios'
import Dashboard from '../components/Dashboard'
import CurrencyInput from 'react-currency-input-field'
import TagsInput from '../components/TagsInput'
import { AuthContext } from '../context/authContext'
import { useNavigate } from 'react-router-dom'
import { Tooltip } from '../components/Tooltip'
import { STRATEGY_OPTIONS, FREQUENCY_OPTIONS, WEEKDAYS, CADENCE_OPTIONS, getBearerToken, convertToNumber, hasDuplicates, convertPercentageToDecimal, convertDecimalToPercentage } from '../utils/config'
import { Controller, useForm } from "react-hook-form";
import { cn } from '../utils/cn'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const strategyOptions = STRATEGY_OPTIONS.map((item) => item.label)

const Settings = () => {
  const navigate = useNavigate();
  const { isAuthenticated, auth } = useContext(AuthContext);
  const customer_id = auth?.user?.id
  const portfolio_id = auth?.user?.portfolio_id

  const defaultValues = {
    // street-rate-update-schedule
    frequency: FREQUENCY_OPTIONS[0],
    weekday: WEEKDAYS[0],
    timeOfDay: '00:00',
    emails: [],

    // Existing Customer Rate Increase Timing
    cadence: CADENCE_OPTIONS[0],
    frequencyTiming: 0,
    totalRevenueIncreaseTarget: 0,
    // Rate Increase Criteria
    maxDollarIncrease: 0,
    minDollarIncrease: 0,
    maxPercentIncrease: 0,
    storeOccupancyThreshold: 0,
    timeSinceLastIncrease: 0,
    timeSinceMoveIn: 0,
    limitAboveStreetRate: 0,
    maxMoveOutProbability: 0,
  }

  const [isLoaded, setIsLoaded] = React.useState(false)
  const [strategy, setStrategy] = React.useState()
  const [isMultipleStrategies, setIsMultipleStrategies] = React.useState(false)
  const { control, handleSubmit, watch, register, setValue, reset, getValues } = useForm({ defaultValues });
  const frequency = watch('frequency')
  const weekday = watch('weekday')
  const timeOfDay = watch('timeOfDay')
  const emails = watch('emails')
  const cadence = watch('cadence')


  const onSubmitForm = (data) => {
    const ecriSettings = {
      // Existing Customer Rate Increase Timing
      totalRevenueIncreaseTarget: convertPercentageToDecimal(data.totalRevenueIncreaseTarget),
      // Rate Increase Criteria
      maxDollarIncrease: convertToNumber(data.maxDollarIncrease),
      minDollarIncrease: convertToNumber(data.minDollarIncrease),
      maxPercentIncrease: convertPercentageToDecimal(data.maxPercentIncrease),
      storeOccupancyThreshold: convertPercentageToDecimal(data.storeOccupancyThreshold),
      timeSinceLastIncrease: convertToNumber(data.timeSinceLastIncrease),
      timeSinceMoveIn: convertToNumber(data.timeSinceMoveIn),
      limitAboveStreetRate: convertToNumber(data.limitAboveStreetRate),
      maxMoveOutProbability: convertPercentageToDecimal(data.maxMoveOutProbability),
    }

    axios.put('/api/portfolio/ecri/' + portfolio_id, { ecri_settings: ecriSettings }).then(response => {
      alert('Settings saved successfully')
    })


    const token = localStorage.getItem('token')
    const headers = { Authorization: getBearerToken(token) }
    const day_of_week = ((WEEKDAYS.indexOf(weekday)) % WEEKDAYS.length) || 0;

    const updateParams = { customer_id, frequency, emails }

    if (frequency !== FREQUENCY_OPTIONS[0]) {
      updateParams.day_of_week = day_of_week
    }

    if (frequency === FREQUENCY_OPTIONS[0] || frequency === FREQUENCY_OPTIONS[1]) {
      // Assuming today's date for the context
      const today = dayjs().format('YYYY-MM-DD');
      // Combine today's date with the timeOfDay
      const datetime = dayjs(`${today}T${timeOfDay}`);
      // Convert to UTC and format
      const utcTime = datetime.utc().format('HH:mm');

      const [hour, minute] = utcTime.split(':')
      updateParams.hour = hour
      updateParams.minute = minute
    }

    if (updateParams) {
      axios.post('/api/cron-job', updateParams, { headers }).then(function (response) {
        //
      }).catch(function (error) {
        console.log(error)
      })
    }
  }

  useEffect(() => {
    if (!customer_id) return
    const token = localStorage.getItem('token')
    const headers = { Authorization: getBearerToken(token) }
    axios.get(`/api/cron-job/${customer_id}`, { headers }).then(function (response) {
      let {
        frequency,
        minute,
        hour,
        day_of_week = 0,
        emails = [],
      } = response.data?.result || {}

      if (hour !== null && hour !== undefined && hour !== '' && parseInt(hour) < 10) {
        hour = `0${hour}`
      }

      if (minute !== null && minute !== undefined && minute !== '' && parseInt(minute) < 10) {
        minute = `0${minute}`
      }

      let timeOfDay = `${hour}:${minute}`;

      // Get the current UTC time in 'YYYY-MM-DD' format
      const utcDate = dayjs().utc().format('YYYY-MM-DD');
      // Combine today's date with the timeOfDay
      const datetime = dayjs(`${utcDate}T${timeOfDay}:00Z`);
      // Convert UTC datetime to local time
      timeOfDay = dayjs(datetime).local().format('HH:mm');

      const formValues = getValues()
      reset({
        ...formValues,
        frequency,
        weekday: WEEKDAYS[day_of_week],
        timeOfDay,
        emails
      })

    }).catch(err => console.log(err))
      .finally(() => {
        setIsLoaded(true)
      });

    axios.get(`/api/facility_profile/${customer_id}/strategies`, { headers }).then(response => {
      const data = response.data?.result || []
      const listStrategies = [...new Set(data.map(i => i.street_rate_strategy).filter(i => i))]
      const isMultipleStrategies = listStrategies.length > 1
      setIsMultipleStrategies(isMultipleStrategies)
      if (!isMultipleStrategies) {
        const option = STRATEGY_OPTIONS.find(i => i.value === listStrategies[0])
        setStrategy(option?.label)
      }
    })

  }, [customer_id, setValue, getValues, reset])

  useEffect(() => {
    if (portfolio_id) {
      const token = localStorage.getItem('token')
      const headers = { Authorization: getBearerToken(token) }
      axios.get(`/api/portfolio/${portfolio_id}`, { headers }).then(function (response) {
        const portfolioData = response.data?.result || {}
        const ecriSettings = portfolioData.ecri_settings || {}
        ecriSettings.totalRevenueIncreaseTarget = convertDecimalToPercentage(ecriSettings.totalRevenueIncreaseTarget)
        ecriSettings.maxPercentIncrease = convertDecimalToPercentage(ecriSettings.maxPercentIncrease)
        ecriSettings.storeOccupancyThreshold = convertDecimalToPercentage(ecriSettings.storeOccupancyThreshold)
        ecriSettings.maxMoveOutProbability = convertDecimalToPercentage(ecriSettings.maxMoveOutProbability)
        const formValues = getValues()
        reset({ ...formValues, ...ecriSettings })
      }).catch(console.log)
    }
  }, [portfolio_id, reset, getValues])

  const onChangeStrategy = label => {
    setStrategy(label)
    setIsMultipleStrategies(false)
    const value = STRATEGY_OPTIONS.find(i => i.label === label)?.value
    // call api for update strategy
    if (!value || !customer_id) return
    const token = localStorage.getItem('token')
    const headers = { Authorization: getBearerToken(token) }
    axios.post(`/api/facility_profile/${customer_id}/save_strategies`, { strategyValue: value }, { headers }).then(response => {
      console.log('response', response)
    })
  }


  if (!isAuthenticated) navigate('/sign-in');

  return (
    <Dashboard>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <div className="w-full rounded-2xl border-2 border-gray-200 px-6 py-6 bg-white mb-4">
          <div>
            <h1 className="font-bold text-gray-500">Street Rate Strategy</h1>
            <div className="rounded-gray-outlined-box">
              <div className="flex items-center w-96">
                <img src="/assets/images/light-bulb.png" className="h-5" alt="Light Bulb Icon" />
                <h1 className="font-bold mx-2">Street Rate Strategy</h1>
                <Tooltip title="Your selection will apply to all facilities.">
                  <img src="/assets/images/danger-circle.png" className="h-4" alt="question" />
                </Tooltip>
              </div>
              {/* multiple */}
              <div className="flex gap-4">
                <SelectGroup options={strategyOptions} selected={strategy} onChange={onChangeStrategy} />
                <button className={cn(`select-group`, isMultipleStrategies ? 'active' : "")} type="button" onClick={() => { }}>Multiple</button>
              </div>
            </div>
          </div>

          <div className="mt-8">
            <h1 className="font-bold text-gray-500">Street Rate Update Strategy</h1>
            <div className="rounded-gray-outlined-box">
              <div className="flex items-center w-96">
                <img src="/assets/images/audio-wave.png" className="h-5" alt="Light Bulb Icon" />
                <h1 className="font-bold mx-2">Frequency</h1>
              </div>
              <Controller
                name="frequency"
                control={control}
                render={({ field }) => <SelectGroup {...field} options={FREQUENCY_OPTIONS} selected={field.value} />}
              />

            </div>
            {frequency === FREQUENCY_OPTIONS[1] && (
              <div className="rounded-gray-outlined-box">
                <div className="flex items-center w-96">
                  <img src="/assets/images/light-bulb.png" className="h-5" alt="Light Bulb Icon" />
                  <h1 className="font-bold mx-2">Day Of Week</h1>
                </div>

                <Controller
                  name="weekday"
                  control={control}
                  render={({ field }) => <SelectGroup {...field} options={WEEKDAYS} selected={field.value} />}
                />
              </div>
            )}
            {(frequency === FREQUENCY_OPTIONS[0] || frequency === FREQUENCY_OPTIONS[1]) && (
              <div className="rounded-gray-outlined-box">
                <div className="flex items-center w-96">
                  <img src="/assets/images/clock.png" className="h-5" alt="Light Bulb Icon" />
                  <h1 className="font-bold mx-2">Time Of Day</h1>
                </div>
                <input
                  type="time"
                  className="w-48 border border-gray-700"
                  {...register("timeOfDay")}
                />
              </div>
            )}
            <div className="rounded-gray-outlined-box">
              <div className='flex w-full'>
                <div className="w-23 flex items-center">
                  <img src="/assets/images/outline-email.png" className="h-5" alt="Light Bulb Icon" />
                  <h1 className="font-bold mx-2">Notification Email</h1>
                  <Tooltip title='Hit ENTER to add a new email.'>
                    <img src="/assets/images/danger-circle.png" className="h-4" alt="" />
                  </Tooltip>
                </div>
                <div className='px-4 py-2'>
                  <Controller
                    name="emails"
                    control={control}
                    render={({ field }) => <TagsInput {...field} placeholder="Email" />}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* <div className="mt-8">
            <h1 className="font-bold text-gray-500">Existing Customer Rate Increase Timing</h1>
            <div className="rounded-gray-outlined-box">
              <div className="flex items-center w-96">
                <img src="/assets/images/clock.png" className="h-5" alt="Light Bulb Icon" />
                <h1 className="font-bold mx-2">Cadence</h1>
                <Tooltip title='Scheduled will establish a set cadence of rate increases for eligible tenants.'>
                  <span className="material-symbols-rounded text-xl text-primary">help</span>
                </Tooltip>
              </div>
              <Controller
                name="cadence"
                control={control}
                render={({ field }) => {
                  return (<SelectGroup {...field} options={CADENCE_OPTIONS} />)
                }}
              />
            </div>
            {cadence === CADENCE_OPTIONS[0] && (
              <div className="rounded-gray-outlined-box">
                <div className="flex items-center w-96">
                  <img src="/assets/images/audio-wave.png" className="h-5" alt="Light Bulb Icon" />
                  <h1 className="font-bold mx-2">Frequency</h1>
                  <Tooltip title='How frequently do you want to perform rate increases?'>
                    <span className="material-symbols-rounded text-xl text-primary">help</span>
                  </Tooltip>
                </div>
                <div>
                  <input
                    type="number"
                    placeholder="8"
                    className="w-20 px-2 py-2 border border-gray-700"
                    {...register("frequencyTiming")}
                  />
                  <span className="ml-2">months</span>
                </div>
              </div>
            )}
          </div> */}

          <div className="mt-6">
            <h1 className="font-bold text-gray-500">Revenue Goal</h1>
            <div className="rounded-gray-outlined-box">
              <div className="flex items-center w-96">
                <span className="text-primary font-bold">%</span>
                <h1 className="font-bold mx-2">Total Revenue Increase Target</h1>
                <Tooltip title="Total Revenue Increase percentage across your entire portfolio. Recommended range: 0.5-2%">
                  <span className="material-symbols-rounded text-xl text-primary">help</span>
                </Tooltip>
              </div>


              <Controller
                name="totalRevenueIncreaseTarget"
                control={control}
                render={({ field: { name, onChange, value, disabled } }) => {
                  return (
                    <CurrencyInput
                      className="w-20"
                      suffix="%"
                      placeholder="5%"
                      disableGroupSeparators={true}
                      decimalsLimit={2}
                      value={value}
                      name={name}
                      onValueChange={onChange}
                    />)
                }} />
            </div>
          </div>

          <div className="mt-8">
            <h1 className="font-bold text-gray-500">Rate Increase Criteria</h1>
            <div className="rounded-gray-outlined-box">
              <div className="flex items-center w-96">
                <span className="text-primary font-bold">$</span>
                <h1 className="font-bold mx-2">Max Dollar Increase</h1>
                <Tooltip title="The maximum incremental dollar increase any one tenant may receive.">
                  <span className="material-symbols-rounded text-xl text-primary">help</span>
                </Tooltip>
              </div>
              <Controller

                name="maxDollarIncrease"
                control={control}
                render={({ field: { name, onChange, value, disabled } }) => {
                  return (
                    <CurrencyInput
                      className="w-20"
                      prefix="$"
                      placeholder="$30"
                      disableGroupSeparators={true}
                      decimalsLimit={2}
                      value={value}
                      name={name}
                      onValueChange={onChange}
                    />)
                }} />
            </div>
            <div className="rounded-gray-outlined-box">
              <div className="flex items-center w-96">
                <span className="text-primary font-bold">$</span>
                <h1 className="font-bold mx-2">Min Dollar Increase</h1>
                <Tooltip title="The minimum incremental dollar increase any one tenant may receive.">
                  <span className="material-symbols-rounded text-xl text-primary">help</span>
                </Tooltip>
              </div>
              <Controller
                name="minDollarIncrease"
                control={control}
                render={({ field: { name, onChange, value, disabled } }) => {
                  return (
                    <CurrencyInput
                      className="w-20"
                      prefix="$"
                      placeholder="$5"
                      disableGroupSeparators={true}
                      decimalsLimit={2}
                      value={value}
                      name={name}
                      onValueChange={onChange}
                    />)
                }}
              />
            </div>
            <div className="rounded-gray-outlined-box">
              <div className="flex items-center w-96">
                <span className="text-primary font-bold">%</span>
                <h1 className="font-bold mx-2">Max Percent Increase</h1>
                <Tooltip title="The maximum percentage increase any one tenant may receive.">
                  <span className="material-symbols-rounded text-xl text-primary">help</span>
                </Tooltip>
              </div>

              <Controller
                name="maxPercentIncrease"
                control={control}
                render={({ field: { name, onChange, value, disabled } }) => {
                  return (
                    <CurrencyInput
                      className="w-20"
                      suffix="%"
                      placeholder="12%"
                      disableGroupSeparators={true}
                      decimalsLimit={2}
                      value={value}
                      name={name}
                      onValueChange={onChange}
                    />)
                }}
              />
            </div>
            <div className="rounded-gray-outlined-box">
              <div className="flex items-center w-96">
                <span className="text-primary font-bold">%</span>
                <h1 className="font-bold mx-2">Store Occupancy Threshold</h1>
                <Tooltip title="The minimum occupancy required for any given facility to qualify for tenant rate increases.">
                  <span className="material-symbols-rounded text-xl text-primary">help</span>
                </Tooltip>
              </div>

              <Controller
                name="storeOccupancyThreshold"
                control={control}
                render={({ field: { name, onChange, value, disabled } }) => {
                  return (
                    <CurrencyInput
                      className="w-20"
                      suffix="%"
                      placeholder="86%"
                      disableGroupSeparators={true}
                      decimalsLimit={2}
                      value={value}
                      name={name}
                      onValueChange={onChange}
                    />)
                }}
              />
            </div>
            <div className="rounded-gray-outlined-box">
              <div className="flex items-center w-96">
                <img src="/assets/images/clock.png" className="h-5" alt="Light Bulb Icon" />
                <h1 className="font-bold mx-2">Time Since Last Increase</h1>
                <Tooltip title="The minimum time in months since a tenant's last rate increase.">
                  <span className="material-symbols-rounded text-xl text-primary">help</span>
                </Tooltip>
              </div>

              <div>
                <input
                  type="number"
                  placeholder="8"
                  className="w-20 px-2 py-2 border border-gray-700"
                  {...register("timeSinceLastIncrease")}
                />{' '}
                <span className="ml-2">months</span>
              </div>
            </div>
            <div className="rounded-gray-outlined-box">
              <div className="flex items-center w-96">
                <img src="/assets/images/clock.png" className="h-5" alt="Light Bulb Icon" />
                <h1 className="font-bold mx-2">Time Since Move-in</h1>
                <Tooltip title="The minimum time in months since a tenant's move-in date.">
                  <span className="material-symbols-rounded text-xl text-primary">help</span>
                </Tooltip>
              </div>

              <div>
                <input
                  type="number"
                  placeholder="8"
                  className="w-20 px-2 py-2 border border-gray-700"
                  {...register("timeSinceMoveIn")}
                />{' '}
                <span className="ml-2">months</span>
              </div>
            </div>
            <div className="rounded-gray-outlined-box">
              <div className="flex items-center w-96">
                <span className="text-primary font-bold">$</span>
                <h1 className="font-bold mx-2">Limit Above Street Rate</h1>
                <Tooltip title="The absolute dollar value over the unit street rate a tenant is occupying.">
                  <span className="material-symbols-rounded text-xl text-primary">help</span>
                </Tooltip>
              </div>

              <Controller
                name="limitAboveStreetRate"
                control={control}
                render={({ field: { name, onChange, value, disabled } }) => {
                  return (
                    <CurrencyInput
                      className="w-20"
                      prefix="$"
                      placeholder="$10"
                      disableGroupSeparators={true}
                      decimalsLimit={2}
                      value={value}
                      name={name}
                      onValueChange={onChange}
                    />)
                }}
              />
            </div>
            <div className="rounded-gray-outlined-box">
              <div className="flex items-center w-96">
                <span className="text-primary font-bold">%</span>
                <h1 className="font-bold mx-2">Max Move-Out Probability</h1>
                <Tooltip title="The desired upper threshold for any given tenant's move-out probability.">
                  <span className="material-symbols-rounded text-xl text-primary">help</span>
                </Tooltip>
              </div>


              <Controller
                name="maxMoveOutProbability"
                control={control}
                render={({ field: { name, onChange, value } }) => {
                  return (
                    <CurrencyInput
                      className="w-20"
                      suffix="%"
                      placeholder="2%"
                      disableGroupSeparators={true}
                      decimalsLimit={2}
                      value={value}
                      name={name}
                      onValueChange={onChange}
                    />
                  )
                }}
              />
            </div>
          </div>
          <div className="mt-8 w-full flex justify-end">
            <button type="submit" className="px-4 px-2 text-primary border-2 border-red-500">
              Save
            </button>
          </div>
        </div>
      </form>
    </Dashboard>
  )
}

export default Settings